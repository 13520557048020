<template>
  <v-app id="app">
    <app-alerts />
    <router-view />
  </v-app>
</template>


<script>
import AppAlerts from "./components/AppAlerts";

export default {
  name: "App",
  components: {
    AppAlerts,
  },

  created() {
    document.title = "MRT Expert — Медицинский центр МРТ и КТ";
    // (function () {
    //   // make vuetify dialogs movable
    //   const d = {};
    //   document.addEventListener("mousedown", (e) => {
    //     const closestDialog = e.target.closest(".v-dialog.v-dialog--active");
    //     if (
    //       e.button === 0 &&
    //       closestDialog != null &&
    //       e.target.classList.contains("v-card__title")
    //     ) {
    //       // element which can be used to move element
    //       d.el = closestDialog; // element which should be moved
    //       d.mouseStartX = e.clientX;
    //       d.mouseStartY = e.clientY;
    //       d.elStartX = d.el.getBoundingClientRect().left;
    //       d.elStartY = d.el.getBoundingClientRect().top;
    //       d.el.style.position = "fixed";
    //       d.el.style.margin = 0;
    //       d.oldTransition = d.el.style.transition;
    //       d.el.style.transition = "none";
    //     }
    //   });
    //   document.addEventListener("mousemove", (e) => {
    //     if (d.el === undefined) return;
    //     d.el.style.left =
    //       Math.min(
    //         Math.max(d.elStartX + e.clientX - d.mouseStartX, 0),
    //         window.innerWidth - d.el.getBoundingClientRect().width
    //       ) + "px";
    //     d.el.style.top =
    //       Math.min(
    //         Math.max(d.elStartY + e.clientY - d.mouseStartY, 0),
    //         window.innerHeight - d.el.getBoundingClientRect().height
    //       ) + "px";
    //   });
    //   document.addEventListener("mouseup", () => {
    //     if (d.el === undefined) return;
    //     d.el.style.transition = d.oldTransition;
    //     d.el = undefined;
    //   });
    //   setInterval(() => {
    //     // prevent out of bounds
    //     // console.log(1);
    //     const dialog = document.querySelector(".v-dialog.v-dialog--active");
    //     if (dialog === null) return;
    //     dialog.style.left =
    //       Math.min(
    //         parseInt(dialog.style.left),
    //         window.innerWidth - dialog.getBoundingClientRect().width
    //       ) + "px";
    //     dialog.style.top =
    //       Math.min(
    //         parseInt(dialog.style.top),
    //         window.innerHeight - dialog.getBoundingClientRect().height
    //       ) + "px";
    //   }, 100);
    // })();
  },

  computed: {},
};
</script>

<style scoped lang="scss">
::v-deep {
  .dialog-to-top {
    align-self: flex-start;
  }
  .table-cell-pointer {
    cursor: pointer;
  }
  .card-title-custom {
    font-size: 15px;
    background-color: #f5f5f5;
    line-height: 1;
  }
  .card-text-custom {
    font-size: 15px;
    color: #000 !important;
  }
  .request-data .v-expansion-panel-header {
    background-color: #f5f5f5;
    min-height: initial;
    font-weight: 500;
  }
  .request-data .row + .row {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }
  @media (max-width: 599px) {
    .request-data .row > div:first-child {
      font-weight: bold;
      padding-bottom: 0;
      padding-top: 6px;
    }
    .request-data .row > div:first-child + div {
      padding-top: 2px;
      padding-bottom: 6px;
    }
  }
}
</style>

<style>
.spoiler {
  filter: blur(3px);
}
.spoiler:hover {
  filter: blur(0);
}
.v-data-footer {
  justify-content: end;
}
.v-data-table-header input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.v-data-table-header input[type="number"] {
  -moz-appearance: textfield;
}

.v-data-table-header th label,
.v-data-table-header th input,
.v-data-table-header th .v-input {
  font-size: 12px !important;
  font-weight: 700;
}
.v-data-table-header .v-input .v-icon.v-icon {
  font-size: 20px;
}
.v-data-table-header .v-text-field > .v-input__control > .v-input__slot:before {
  opacity: 0;
}
</style>