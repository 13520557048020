module.exports = {
	"patient": "Пациенты",
	"order": "Обращения",
    "branch": "Филиалы",
    "doctor": "Доктора",
    "reception": "Регистратура",
    "assistant": "Лаборанты",
    "service": "Услуги",
    "suborder": "Записи",
    "title": {
        "assistant": "Лаборатория",
        "reception": "Регистратура",
        "doctor": "Доктор",
        "branch-admin": "Админ",
        "patient": "Получение результата анализа"
    }
    
}