import Vue from 'vue'
import VueRouter from 'vue-router'
import Auth from '../views/login/AuthPage.vue'
//import Home from '../views/Home.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login/:loginType(patient)',
    name: 'login',
    component: () => import('../views/login/PatientAuthPage.vue'),
  },
  {
    path: '/login/:loginType*',
    name: 'login',
    component: Auth
  },
  {
    path: '/:role(super-admin)',
    name: 'super-admin',
    component: () => import('../views/LayoutView.vue'),
    children: [
      {
        path: '',
        redirect: 'branch/list'
      },
      {
        path: ':dir/(list)*',
        component: () => import('../components/default/list/DefaultList.vue'),
      },
      {
        path: ':dir/view/:id',
        component: () => import('../views/DefaultView.vue'),
      },
    ],
  },
  {
    path: '/:role(branch-admin)',
    name: 'branch-admin',
    component: () => import('../views/LayoutView.vue'),
    children: [
      {
        path: '',
        redirect: 'reception/list'
      },
      // {
      //   path: 'branch/view/:id',
      //   component: () => import('../views/super-admin/branch/BranchView.vue'),
      // },
      {
        path: ':dir/(list)*',
        component: () => import('../components/default/list/DefaultList.vue'),
      },
      {
        path: ':dir/view/:id',
        component: () => import('../views/DefaultView.vue'),
      },
    ],
  },
  {
    path: '/:role(reception)',
    name: 'reception',
    component: () => import('../views/LayoutView.vue'),
    children: [
      {
        path: '',
        redirect: 'suborder/all/calendar'
      },
      {
        path: ':dir(patient)/(list)*',
        component: () => import('../views/reception/patient/PatientList.vue'),
      },
      // {
      //   path: ':dir(patient)/view/:id',
      //   component: () => import('../views/reception/patient/PatientView.vue'),
      // },
      {
        path: ':dir(order)/view/:id',
        component: () => import('../views/reception/order/OrderView.vue'),
      },
      {
        path: ':dir(order)/:subdir(draft)/(list)*',
        component: () => import('../components/default/list/DefaultList.vue'),
        props: {
          getDataUrl: "order?status_id=1"
        }
      },
      {
        path: ':dir(order)/:subdir(processing)/(list)*',
        component: () => import('../components/default/list/DefaultList.vue'),
        props: {
          getDataUrl: "order?status_id=2"
        }
      },
      {
        path: ':dir(order)/:subdir(completed)/(list)*',
        component: () => import('../components/default/list/DefaultList.vue'),
        props: {
          getDataUrl: "order?status_id=3"
        }
      },
      {
        path: ':dir(suborder)/:subdir(all)/(calendar)*',
        component: () => import('../views/assistant/suborder/SuborderCalendar.vue'),
        props: {
          getDataUrl: "suborder/all"
        }
      },
      {
        path: ':dir(suborder)/:subdir(new)/(list)*',
        component: () => import('../components/default/list/DefaultList.vue'),
        props: {
          getDataUrl: "suborder?status_id=1"
        }
      },
      {
        path: ':dir(suborder)/:subdir(waithing-for-doctor)/(list)*',
        component: () => import('../components/default/list/DefaultList.vue'),
        props: {
          getDataUrl: "suborder?status_id=2"
        }
      },
      {
        path: ':dir(suborder)/:subdir(in-process-by-doctor)/(list)*',
        component: () => import('../components/default/list/DefaultList.vue'),
        props: {
          getDataUrl: "suborder?status_id=3"
        }
      },
      {
        path: ':dir(suborder)/:subdir(rejected)/(list)*',
        component: () => import('../components/default/list/DefaultList.vue'),
        props: {
          getDataUrl: "suborder?status_id=5"
        }
      },
      {
        path: ':dir(suborder)/:subdir(revoked)/(list)*',
        component: () => import('../components/default/list/DefaultList.vue'),
        props: {
          getDataUrl: "suborder?status_id=4"
        }
      },
      {
        path: ':dir(suborder)/:subdir(canceled)/(list)*',
        component: () => import('../components/default/list/DefaultList.vue'),
        props: {
          getDataUrl: "suborder?status_id=7"
        }
      },
      {
        path: ':dir(suborder)/:subdir(completed)/(list)*',
        component: () => import('../components/default/list/DefaultList.vue'),
        props: {
          getDataUrl: "suborder?status_id=6"
        }
      },
      {
        path: ':dir/(list)*',
        component: () => import('../components/default/list/DefaultList.vue'),
      },
      {
        path: ':dir/view/:id',
        component: () => import('../views/DefaultView.vue'),
      },
    ],
  },
  {
    path: '/:role(doctor)',
    name: 'doctor',
    component: () => import('../views/LayoutView.vue'),
    children: [
      {
        path: '',
        redirect: 'suborder/waithing-for-doctor/list'
      },
      {
        path: ':dir(suborder)/:subdir(waithing-for-doctor)/(list)*',
        component: () => import('../views/doctor/DefaultList.vue'),
        props: {
          getDataUrl: "suborder?status_id=2"
        }
      },
      {
        path: ':dir(suborder)/:subdir(in-process-by-doctor)/(list)*',
        component: () => import('../views/doctor/DefaultList.vue'),
        props: {
          getDataUrl: "suborder?status_id=3"
        }
      },
      {
        path: ':dir(suborder)/:subdir(completed)/(list)*',
        component: () => import('../views/doctor/DefaultList.vue'),
        props: {
          getDataUrl: "suborder?status_id=6"
        }
      },
      {
        path: ':dir/(list)*',
        component: () => import('../components/default/list/DefaultList.vue'),
      },
      {
        path: ':dir/view/:id',
        component: () => import('../views/DefaultView.vue'),
        // component: () => import('../views/doctor/DefaultView.vue'),
      },
    ],
  },
  {
    path: '/:role(assistant)',
    name: 'assistant',
    component: () => import('../views/LayoutView.vue'),
    children: [
      {
        path: '',
        redirect: 'suborder/all/calendar'
      },
      {
        path: ':dir(suborder)/:subdir(all)/(calendar)*',
        component: () => import('../views/assistant/suborder/SuborderCalendar.vue'),
        props: {
          getDataUrl: "suborder/all"
        }
      },
      {
        path: ':dir(suborder)/:subdir(new)/(list)*',
        component: () => import('../components/default/list/DefaultList.vue'),
        props: {
          getDataUrl: "suborder?status_id=1"
        }
      },
      {
        path: ':dir(suborder)/:subdir(waithing-for-doctor)/(list)*',
        component: () => import('../components/default/list/DefaultList.vue'),
        props: {
          getDataUrl: "suborder?status_id=2"
        }
      },
      {
        path: ':dir(suborder)/:subdir(in-process-by-doctor)/(list)*',
        component: () => import('../components/default/list/DefaultList.vue'),
        props: {
          getDataUrl: "suborder?status_id=3"
        }
      },
      {
        path: ':dir(suborder)/:subdir(rejected)/(list)*',
        component: () => import('../components/default/list/DefaultList.vue'),
        props: {
          getDataUrl: "suborder?status_id=5"
        }
      },
      {
        path: ':dir(suborder)/:subdir(revoked)/(list)*',
        component: () => import('../components/default/list/DefaultList.vue'),
        props: {
          getDataUrl: "suborder?status_id=4"
        }
      },
      {
        path: ':dir(suborder)/:subdir(canceled)/(list)*',
        component: () => import('../components/default/list/DefaultList.vue'),
        props: {
          getDataUrl: "suborder?status_id=7"
        }
      },
      {
        path: ':dir(suborder)/:subdir(completed)/(list)*',
        component: () => import('../components/default/list/DefaultList.vue'),
        props: {
          getDataUrl: "suborder?status_id=6"
        }
      },
      {
        path: ':dir(patient)/(list)*',
        component: () => import('../views/reception/patient/PatientList.vue'),
      },
      {
        path: ':dir(order)/:subdir(draft)/(list)*',
        component: () => import('../components/default/list/DefaultList.vue'),
        props: {
          getDataUrl: "order?status_id=1"
        }
      },
      {
        path: ':dir(order)/:subdir(processing)/(list)*',
        component: () => import('../components/default/list/DefaultList.vue'),
        props: {
          getDataUrl: "order?status_id=2"
        }
      },
      {
        path: ':dir(order)/:subdir(completed)/(list)*',
        component: () => import('../components/default/list/DefaultList.vue'),
        props: {
          getDataUrl: "order?status_id=3"
        }
      },
      // {
      //   path: ':dir(suborder)/view/:id',
      //   component: () => import('../views/assistant/suborder/SuborderView.vue'),
      // },
      {
        path: ':dir/(list)*',
        component: () => import('../components/default/list/DefaultList.vue'),
      },
      {
        path: ':dir/view/:id',
        component: () => import('../views/DefaultView.vue'),
      },
    ],
  },
  {
    path: '/patient',
    name: 'patient',
    component: () => import('../views/patient/LayoutView.vue'),
    children: [
      {
        path: '',
        redirect: 'result/view/my'
      },
      // {
      //   path: ':dir/(list)*',
      //   component: () => import('../components/default/list/DefaultList.vue'),
      // },
      {
        path: ':dir/view/:id',
        component: () => import('../views/DefaultView.vue'),
      },
    ],
  },
  { 
    path: "*", 
    component: () => import('../views/NotFound404.vue'),
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  // console.log(to);
  if(to.path == '/') {
    if(store.getters.getAccessToken) {
      next(store.getters.getCurrentRole);
    } else {
      next('/login/' + store.getters.getCurrentRole);
    }
  } else if(to.name == 'login') {
    if(store.getters.getAccessToken) {
      next(store.getters.getCurrentRole);
    } else {
      next();
    }
  } else {
    if(store.getters.getAccessToken) {
      next();
    } else {
      store.dispatch('logout');
    }
  }
  // next();
});

export default router
