module.exports = {
    "Обязательное поле": "Обязательное поле",
    "Значение слишком короткое": "Значение слишком короткое",
    "Значение слишком длинное": "Значение слишком длинное",
    "Некорректный E-mail": "Некорректный E-mail",
    "Некорректный адрес сайта": "Некорректный адрес сайта",
    "Некорректные координаты": "Некорректные координаты",
    "Неправильный формат": "Неправильный формат",
    "Минимальное допустимое значение": "Минимальное допустимое значение",
    "Максимальное допустимое значение": "Максимальное допустимое значение",
    "Время": "Время",
    "Выберите ЭЦП": "Выберите ЭЦП",
    "Введите пароль": "Введите пароль",
    "Выберите файл": "Выберите файл",
    "Ошибка": "Ошибка",
    "Размер файла не должен быть больше": "",
    "Количество загружаемого файла не должно быль больше": "Количество загружаемого файла не должно быль больше",
    "Выбрать": "Выбрать",
    "Выход": "Выход",
    "Места": "Места",
    "Председатель комиссии": "Председатель комиссии",
    "Организации": "Организации",
    "Добавить председателя комиссии": "Добавить председателя комиссии",
    "Редактировать": "Редактировать",
    "Сохранить": "Сохранить",
    "Скачать файл": "Скачать файл",
    "Посмотреть": "Посмотреть",
    "Добавить новое место": "Добавить новое место",
    "Отдел обр.": "Отдел обр.",
    "Орг. орб.": "Орг. орб.",
    "Пароль": "Пароль",
    "Данные организации": "Данные организации",
    "Объявленные места": "Объявленные места",
    "Загрузить файл": "Загрузить файл",
    "Удалить": "Удалить",
    "Удалить файла": "Удалить файла",
    "Вы действительно хотите удалить этот файл?": "Вы действительно хотите удалить этот файл?",
    "Отмена": "Отмена",
    "ОК": "ОК",
    "Вы действительно хотите удалить эту запись?": "Вы действительно хотите удалить эту запись",
    "Мои филиалы": "Мои филиалы",
    "Мои сотрудники": "Мои сотрудники",
    "Удалить запись": "Удалить запись",
    "Местонахождение": "Местонахождение",
    "Координаты": "Координаты",
    // "": "",
}