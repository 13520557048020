import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        current: "ru"
    }
    // theme: {
    //     themes: {
    //         light: {
    //             primary: '#3f51b5',
    //             secondary: '#f44336',
    //             accent: '#00bcd4',
    //             error: '#ff5722',
    //             warning: '#ff9800',
    //             info: '#2196f3',
    //             success: '#8bc34a'
    //         },
    //     },
    // },
});

