import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import './axios.config.js'
import router from './router'
import store from './store'
import i18n from './i18n'
import { TiptapVuetifyPlugin } from "tiptap-vuetify";
import { VueMaskDirective } from 'v-mask'


Vue.use(TiptapVuetifyPlugin, {
	vuetify,
	iconsGroup: 'mdi'
  });


Vue.directive('mask', VueMaskDirective);
// import VueMask from 'v-mask'

// Vue.use(VueMask);

Vue.config.productionTip = true
Vue.prototype.$http = axios;

i18n.locale = store.getters.getLanguage;

new Vue({
	vuetify,
	router,
	store,
    i18n,
	render: h => h(App)
}).$mount('#app')
