<template>
  <v-app>
    <div class="login-page">
      <v-row>
        <v-col cols="12" lg="6" md="6" sm="7">
          <div class="form">
            <v-row class="justify-center">
              <img src="@/assets/images/logo.png" width="150px" />
            </v-row>
            <br /><br />
            <v-form
              class="login-form"
              ref="form"
              @submit.prevent="handleSubmit"
            >
              <v-select
                class="mb-2"
                :items="roles"
                v-model="loginType"
                item-text="name"
                item-value="id"
                @change="changeLoginType()"
                label="Роль"
              >
                ></v-select
              >
              <v-text-field
                autocomplete="username"
                filled
                color="indigo lighten-1"
                :label="$t('E-mail')"
                v-model="login"
                type="text"
                :rules="[
                  (v) => !!v || $t('Введите пароль'),
                  (v) => !v || /.+@.+\..+/.test(v) || $t('Некорректный E-mail'),
                ]"
                @click:append="showPassword = !showPassword"
              ></v-text-field>
              <v-text-field
                autocomplete="current-password"
                filled
                color="indigo lighten-1"
                :label="$t('Пароль')"
                v-model="password"
                :type="showPassword ? 'text' : 'password'"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[(v) => !!v || $t('Введите пароль')]"
                @click:append="showPassword = !showPassword"
              ></v-text-field>

              <v-flex class="d-flex justify-end">
                <v-btn
                  height="50px"
                  dark
                  block
                  color="light-blue darken-3"
                  class="login mt-3"
                  type="submit"
                  :loading="loading"
                >
                  {{ $t("Войти") }}
                </v-btn>
              </v-flex>
            </v-form>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-app>
</template>

<script>
export default {
  mounted() {
    if (this.$route.params.loginType) {
      // if (
      //   this.$route.params.loginType == "super-admin" ||
      //   this.$route.params.loginType == "branch-admin"
      // ) {
      this.loginType = this.$route.params.loginType;
      // }
    }
    this.changeLoginType();
  },

  data() {
    return {
      showPassword: false,
      login: "",
      password: "",
      loading: false,
      loginType: this.$store.getters.getCurrentRole || "patient",
      languages: {
        kk: "Қазақша",
        ru: "Русский",
      },
      roles: [
        {
          id: "super-admin",
          name: this.$t("Супер админ"),
        },
        {
          id: "branch-admin",
          name: this.$t("Админ"),
        },
        {
          id: "doctor",
          name: this.$t("Доктор"),
        },
        {
          id: "reception",
          name: this.$t("Регистратура"),
        },
        {
          id: "assistant",
          name: this.$t("Лаборант"),
        },
        {
          id: "patient",
          name: this.$t("Пациент"),
        },
      ],
    };
  },
  methods: {
    changeLoginType() {
      this.$store.dispatch("setCurrentRole", this.loginType);
      if (this.$route.params.loginType != this.loginType) {
        this.$router.push({ path: "/login/" + this.loginType });
      }
    },
    async handleSubmit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        await this.$http
          .post("/sign-in", {
            email: this.login,
            password: this.password,
          })
          .then((response) => {
            this.$store
              .dispatch("login", {
                loginType: this.loginType,
                data: response.data,
              })
              .then(() => {
                this.$router.push({ path: "/" + this.loginType });
              });
          })
          .catch(() => {});
        this.loading = false;
      }
    },
    // changeLanguage(lang) {
    //   this.$store.commit("setLanguage", lang);
    //   //location.reload();
    // },
  },
  watch: {},
};
</script>
<style scoped>
.login-page {
  width: 800px;
  max-width: 100%;
  margin: auto;

  background-image: url("~@/assets/images/bg.jpg");
  background-size: cover;
  background-position: center right;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
  padding: 40px;
}
.form {
}
.v-application {
  background: rgb(5, 37, 105);
  background: -moz-linear-gradient(
    65deg,
    rgba(5, 37, 105, 1) 0%,
    rgba(9, 43, 121, 1) 41%,
    rgba(11, 152, 219, 1) 100%
  );
  background: -webkit-linear-gradient(
    65deg,
    rgba(5, 37, 105, 1) 0%,
    rgba(9, 43, 121, 1) 41%,
    rgba(11, 152, 219, 1) 100%
  );
  background: linear-gradient(
    65deg,
    rgba(5, 37, 105, 1) 0%,
    rgba(9, 43, 121, 1) 41%,
    rgba(11, 152, 219, 1) 100%
  );
}
</style>