import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import config from "./config"
import router from './router'
//import i18n from './i18n'

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		accessToken: localStorage.getItem('accessToken'),
		alerts:[],
		language: localStorage.getItem('language') || 'ru',
		currentRole: localStorage.getItem('currentRole') || '',
		userInfo: JSON.parse(localStorage.getItem('userInfo')) || {},
		uploadService: [],
		fileIsLoading: false,
	},

	mutations: {
		alert(state, {alert, adding}) {
            adding ? state.alerts.push(alert)
				: state.alerts.shift()
		},
		setLanguage(state, lang) {
			localStorage.setItem('language', lang);
			state.language = lang;
			//i18n.locale = lang;
		},
		setCurrentRole(state, role) {
			localStorage.setItem('currentRole', role);
			state.currentRole = role;
		},
		logout(state) {
			state.accessToken = '';
			state.userInfo = {};
			localStorage.clear();
			localStorage.setItem('currentRole', state.currentRole);
			delete axios.defaults.headers.common['Authorization'];
		},
		addToUploadService(state, upload) {
			// console.log('addToUploadService', upload);
            state.uploadService.push(upload);
		},
		fileIsLoading(state, val) {
            state.fileIsLoading = val;
		},
	},

	actions: {
		login({ dispatch, state }, data) {
			state.accessToken = data.data.token;
			localStorage.setItem('accessToken', state.accessToken);
			axios.defaults.headers.common['Authorization'] = 'Bearer '+state.accessToken;
			localStorage.setItem('userInfo', JSON.stringify(data.data.user));
			state.userInfo = data.data.user;
			dispatch('setCurrentRole', data.loginType);
            
		},
		setCurrentRole({ commit, dispatch }, role) {
			commit('setCurrentRole', role);
			dispatch('changeBaseUrl');
		},
		changeBaseUrl({ getters, state }) {
			axios.defaults.baseURL = getters.getBaseUrl+getters.getApiVersion+(state.currentRole ? '/'+state.language+'/'+state.currentRole : '');
		},
		alertAdd({ commit }, alert) {
			commit('alert', { alert, adding: true});
			setTimeout(() => commit('alert', { alert, adding: false }), 5000);
		},
		logout({commit, dispatch, state}) {
			let history = (router.history.pending ?? router.history.current);
            console.log(history);
			commit('logout');
			dispatch('changeBaseUrl');
			//let url = '/#/login'+(history.matched[0] ? '/'+history.matched[0].name : '')+(history.fullPath != '/' ? '?url='+encodeURIComponent(history.fullPath) : '');
			// let url = '/#/login';

			router.push('/login/'+state.currentRole);
			// location.href = url;
			//location.reload();
			//location.href = '/portal/#/login'+(history.matched[0] ? '/'+history.matched[0].name : '')+(history.fullPath != '/' ? '?url='+encodeURIComponent(history.fullPath) : '');
		},
		addToUploadService({ commit }, upload) {
			console.log('addToUploadService', upload);
			commit('addToUploadService', upload);
		},
		fileIsLoading({ commit }, val) {
			commit('fileIsLoading', val);
		},
	},

	getters: {
		getBaseUrl() {
			return config.API_URL;
		},
		getApiVersion() {
			return config.API_VERSION;
		},
		getAccessToken(state) {
			return state.accessToken;
		},
		getCurrentRole(state) {
			return state.currentRole;
		},
		getUserInfo(state) {
			return state.userInfo;
		},
		getLanguage(state) {
			return state.language;
		},
		getUploadService(state) {
			return state.uploadService;
		},
		getFileIsLoading(state) {
			return state.fileIsLoading;
		}
	}

});